import { graphql } from "gatsby";
import * as React from "react";
import { WmkSeo } from "wmk-seo";
import { DocumentFields } from "../fragments/NodeDocumentFields";
import { ContentfulImageQuery } from "wmk-image";
import DipraButton from "../components/ui/DipraButton";
import { colors } from "../vars/palette";
import { Col, Container, Row } from "react-bootstrap";
import { Type } from "../components/ui/Typography";
import { saveAs } from "file-saver";
import styled from "styled-components";

// import { graphql } from "gatsby";
// import JSZip from "jszip";
// import * as React from "react";
// import { saveAs } from "file-saver";
// import JSZipUtils from "jszip-utils";

// const AssetDownload = ({
//   data
// }: {
//   data: { pdf: { edges: { node: { title: string; url: string } }[] } };
// }) => {
//   const [isDownloadStarted, setIsDownloadStarted] = React.useState(false);
//   let zip = new JSZip();
//   var count = 0;
//   const pdfs = data.pdf;
//   const DownloadFiles = () => {
//     data.pdf.edges.map((file) => {
//       JSZipUtils.getBinaryContent(file.node.url, function (err, data) {
//         if (err) {
//           throw err; // or handle the error
//         }
//         zip.file(`${file.node.title}.pdf`, data, { binary: true });
//         count++;

//         if (count == pdfs.edges.length) {
//           zip.generateAsync({ type: "blob" }).then(function (content) {
//             saveAs(content, "filename");
//           });
//         }
//       });
//     });
//   };

//   return (
//     <>
//       {!isDownloadStarted ? (
//         <button
//           onClick={() => {
//             setIsDownloadStarted(true);
//             DownloadFiles();
//           }}>
//           Download all pdfs
//         </button>
//       ) : (
//         <p>Please wait for the download to finish</p>
//       )}
//     </>
//   );
// };

const ButtonContainer = styled.div<{
  invisible: boolean;
  color: string;
  textColor: string;
  rounded: boolean;
  fluid: boolean;
  fontSize: string;
  bold: boolean;
  hoverColor: string;
  innerPadding: string;
  height: string;
  style: CSS.Properties;
}>`
max-width: 100%;
  align-items: center;
  border-bottom: ${(props) =>
    props.invisible ? `6px solid transparent` : "none"};
  background-color: ${(props) =>
    props.color ? props.color : colors.white.hex};
  border-radius: ${(props) => (props.rounded ? "10px" : "unset")};
  box-sizing: border-box;
  color: ${(props) =>
    props.textColor ? props.textColor : colors.darkBlue.hex};
  cursor: pointer;
  display: ${(props) => (props.fluid ? "flex" : "inline-flex")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  &:hover {
    background-color: ${(props) =>
      props.hoverColor ? props.hoverColor : colors.darkBlue.hex};
    border-bottom: ${(props) =>
      props.invisible ? `6px solid ${colors.green.hex}` : "none"};
    color: ${(props) =>
      props.invisible ? colors.black.hex : colors.white.hex};
  }

  &:active {
    background-color: ${(props) =>
      props.hoverColor ? props.hoverColor : colors.darkBlue.hex};
    border-bottom: ${(props) =>
      props.invisible ? `6px solid ${colors.green.hex}` : "none"};
    color: ${(props) =>
      props.invisible ? colors.black.hex : colors.white.hex};
  }

  a {
    align-items: center;
    color: unset;
    display: ${(props) => (props.fluid ? "block" : "contents")};
    display: flex;
    height: 100%;
    justify-content: center;
    padding: ${(props) =>
      props.innerPadding ? props.innerPadding : "0.25rem 2rem"};
    text-decoration: none;
    width: 100%;

    &:hover {
      text-decoration: none;
      color: unset;
    }
  }`

const DocumentNode = ({
  data,
  pageContext
}: {
  pageContext: { slug: string };
  data: DocumentNodeQuery;
}) => {
  const [isUsingIphone, setIsUsingIphone] = React.useState(false);
  const [isUsingIpad, setIsUsingIpad] = React.useState(false);

  React.useEffect(() => {
    if (navigator?.userAgent) {
      setIsUsingIphone(navigator.userAgent.match(/iPhone/i));
      setIsUsingIpad(/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1);
    }
    // navigator?.userAgent ? navigator.userAgent.match(/iPhone/i) : null
    // navigator.userAgent.match(/iPhone/i)
  }, []);
  // let userAgent = navigator.userAgent;
  // console.log("userAgent", userAgent);
  // console.log("props", props)
  // if (navigator.userAgent.match(/iPhone/i)) {
  // }
  // console.log("data", data);
  const downloadFile = () => {
    // data.pdf.edges.map((file) => {
    //   JSZipUtils.getBinaryContent(file.node.url, function (err, data) {
    //     if (err) {
    //       throw err; // or handle the error
    //     }
    //     zip.file(`${file.node.title}.pdf`, data, { binary: true });
    //     count++;

    //     if (count == pdfs.edges.length) {
    //       zip.generateAsync({ type: "blob" }).then(function (content) {
    //         saveAs(content, "filename");
    //       });
    //     }
    //   });
    // });
    // const file = new Blob(
    //   data.contentfulDocument.file,
    //   `${data.contentfulDocument.file.filename}.pdf`
    // );
    // console.log("file", file)
    saveAs(data.contentfulDocument.file.url, `${data.contentfulDocument.file.filename}.pdf`);
  };

  const handleClick = (e) => {
    // console.log("go")
    e.preventDefault();
    downloadFile();
  };
  return (
    <>
      <WmkSeo.Meta
        title={data?.contentfulDocument?.title}
        siteTitle={data?.site?.siteMetadata?.title}
        baseUrl={data?.site?.siteMetadata?.baseUrl}
        slug={pageContext?.slug}
        path={"/docs/"}
        description={data?.contentfulDocument?.file?.description}
        ogImageUrl={data?.logo?.gatsbyImageData?.images?.fallback?.src}
        twitterImageUrl={data?.logo?.gatsbyImageData?.images?.fallback?.src}
        twitterHandle={data?.site?.siteMetadata?.twitter}
        isSiteTitleDisplayed
      />

      {isUsingIphone || isUsingIpad ? (
        <Container>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "30px 30px",
                textAlign: "center"
              }}>
              <Type.IconCardHeadline style={{ paddingBottom: "1rem" }}>
                There seems to be an issue loading the pdf display. <br />
                You can download the pdf here.
              </Type.IconCardHeadline>
              {/* <DipraButton
                link={{
                  to: data.contentfulDocument.file.url,
                  text: "Download"
                }}
                download={data.contentfulDocument.file.filename}
                color={colors.darkBlue.hex}
                textColor={colors.white.hex}
                hoverColor={colors.blue.hex}
                style={{ justifyContent: "center" }}
                onClick={(e) => {
                  handleClick(e)
                  downloadFile()}}>
                Download
              </DipraButton> */}
              <ButtonContainer color={colors.darkBlue.hex}
                textColor={colors.white.hex}
                hoverColor={colors.blue.hex}
                style={{ justifyContent: "center" }}>
              <a href="#" onClick={(e) => handleClick(e)}>Download</a>

              </ButtonContainer>
            </Col>
          </Row>
        </Container>
      ) : (
        <object
          width="100%"
          data={data?.contentfulDocument?.file?.url}
          type="application/pdf"
          style={{ height: "100vh", minHeight: "100vh" }}>
          <iframe
            width="100%"
            style={{ height: "100vh", minHeight: "100vh" }}
            src={data?.contentfulDocument?.file?.url}>
            <p>Unfortunately, this browser does not support PDF rendering</p>
          </iframe>
        </object>
      )}
    </>
  );
};

export default DocumentNode;

export interface DocumentNodeQuery {
  contentfulDocument: DocumentFields;
  site: {
    siteMetadata: {
      description: string;
      baseUrl: string;
      title: string;
      twitter: string;
    };
  };
  logo: ContentfulImageQuery;
}

export const query = graphql`
  query documentQuery($slug: String) {
    contentfulDocument(slug: { eq: $slug }) {
      ...NodeDocumentFields
    }
    site {
      siteMetadata {
        baseUrl
        description
        title
        twitter
      }
    }
    logo: contentfulAsset(title: { eq: "Dipra Gradient Logo with Tag" }) {
      ...NodeImageFields
    }
  }
`;
